import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
  resources: { // Import translations you made from certain path.
    en: {
      translation: require('../locales/en/translation.json')
    },
    de: {
      translation: require('../locales/de/translation.json')
    },
    fr: {
      translation: require('../locales/fr/translation.json')
    },
    nl: {
      translation: require('../locales/nl/translation.json')
    },
    it: {
      translation: require('../locales/it/translation.json')
    },
    es: {
      translation: require('../locales/es/translation.json')
    }
  },
  debug: process.env.NODE_ENV === "development",
  fallbackLng: 'fr', // Set default language
  interpolation: {
    escapeValue: false,
  },
})

export default i18n